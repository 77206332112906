export const environment = {
  production: false,
  clientId: '',
  publicKey: '',
  homePath: '/main/home',
  baseApiUrl: '',
  authServiceUrl: '',
  userServiceUrl: '',
  logLevel: 5,
};
