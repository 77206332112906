export const NEBULAR_COMP_ITEMS = [
  {
    title: 'Button',
    url: 'https://akveo.github.io/nebular/docs/components/button/overview#nbbuttoncomponent',
    home: true,
    pathMatch: 'prefix',
    image: '../../../assets/images/components/Default button.svg',
  },
  {
    title: 'Input',
    url: 'https://akveo.github.io/nebular/docs/components/input/overview#nbinputdirective',
    home: true,
    pathMatch: 'prefix',
    image: '../../../assets/images/components/Rectangle 37.svg',
  },
  {
    title: 'Checkbox',
    url: 'https://akveo.github.io/nebular/docs/components/checkbox/examples#nbcheckboxcomponent',
    home: true,
    pathMatch: 'prefix',
    image: '../../../assets/images/components/Checkbox.svg',
  },
  {
    title: 'Radio Button',
    url: 'https://akveo.github.io/nebular/docs/components/radio/examples#nbradiocomponent',
    // if we want to see components in Card layout we can use this link
    // link: '/main/components/nebular-comp',
    home: true,
    pathMatch: 'prefix',
    image: '../../../assets/images/components/Checkbox.svg',
  },
  {
    title: 'Card',
    url: 'https://akveo.github.io/nebular/docs/components/card/overview#nbcardcomponent',
    home: true,
    pathMatch: 'prefix',
    image: '../../../assets/images/components/card.png',
  },
  {
    title: 'Icon',
    url: 'https://akveo.github.io/nebular/docs/components/icon/overview#nbiconcomponent',
    home: true,
    pathMatch: 'prefix',
    image: '../../../assets/images/components/icon.png',
  },
  {
    title: 'Toastr',
    url: 'https://akveo.github.io/nebular/docs/components/toastr/overview#nbtoastrservice',
    home: true,
    pathMatch: 'prefix',
    image: '../../../assets/images/components/toastr.png',
  },
  {
    title: 'Menu',
    url: 'https://akveo.github.io/nebular/docs/components/menu/overview#nbmenucomponent',
    home: true,
    pathMatch: 'prefix',
    image: '../../../assets/images/components/menu.png',
  },
];
