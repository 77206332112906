<div class="nav-container">
  <div class="header-container">
    <div class="logo-container">
      <nb-icon
        icon="menu-outline"
        class="cursor-pointer menu-icon"
        (click)="toggle()"
      ></nb-icon>
      <a
        class="logo sdlc-logo"
        href="javascript:void(0)"
        (click)="navigateHome('/main/home')"
      >
        <img src="../../assets/images/home/sdlc_ai.png" alt="logo" />
      </a>
    </div>
  </div>
</div>
