import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateService, TranslateStore} from '@ngx-translate/core';
import {APP_CONFIG} from '@project-lib/app-config';
import {HeaderComponent} from '@project-lib/components/header/header.component';
import {AuthModule} from '@project-lib/components/index';
import {SidebarComponent} from '@project-lib/components/sidebar/sidebar.component';
import {ApiService} from '@project-lib/core/api';
import {CoreModule} from '@project-lib/core/core.module';
import {SystemStoreFacadeService} from '@project-lib/core/index';
import {
  LocalizationModule,
  TranslationService,
} from '@project-lib/core/localization';
import {EnvAdapterService} from '@project-lib/core/store/adapters';
import {IconPacksManagerService} from '@project-lib/theme/services';
import {ThemeModule} from '@project-lib/theme/theme.module';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {SpinnerInterceptor} from './shared/spinner.interceptor';

@NgModule({
  declarations: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LocalizationModule,
    CoreModule,
    AuthModule,
    ThemeModule.forRoot('default'),
    BrowserAnimationsModule,
    NgxSpinnerModule.forRoot({type: 'square-jelly-box'}),
    HeaderComponent,
    SidebarComponent,
  ],
  providers: [
    TranslationService,
    TranslateService,
    IconPacksManagerService,
    TranslateStore,
    SystemStoreFacadeService,
    EnvAdapterService,
    ApiService,
    {
      provide: APP_CONFIG,
      useValue: environment,
    },

    {provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
