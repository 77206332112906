export const environment = {
  production: true,
  clientId: '',
  publicKey: '',
  homePath: '/main/home',
  baseApiUrl: '',
  authServiceUrl: '',
  userServiceUrl: '',
  logLevel: 0,
};
