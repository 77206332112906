<nb-layout>
  <nb-layout-column
    class="loginImg-wrapper"
    style="
      background-image: url('../../assets/images/auth/ai-tend.jpg');
      background-repeat: no-repeat;
      background-size: cover;
    "
  >
    <!-- <div class="login-img">
      <img
        width="100%"
        height="100%"
        src="../../assets/images/auth/ai-tend.jpg"
        alt="loginImg"
      />
    </div> -->
    <div class="login-content">
      <nb-auth-block class="h-100 m-0">
        <router-outlet></router-outlet>
      </nb-auth-block>
    </div>
  </nb-layout-column>
</nb-layout>
