<div class="sidebar-container">
  <nb-sidebar-footer
    class="footer"
    [ngStyle]="{visibility: toggleFooter ? 'hidden' : 'visible'}"
  >
    <!-- <nb-actions>
      <nb-action class="pl-0 pr-5 footer-action">
        <a class="footer-links" href="javascript:void(0)">Privacy</a>
      </nb-action>
      <nb-action class="pr-0 pl-5 footer-divider footer-action">
        <a class="footer-links" href="javascript:void(0)">Terms & conditions</a>
      </nb-action>
    </nb-actions> -->
    <!-- © Boiler Plate -->
  </nb-sidebar-footer>
</div>
