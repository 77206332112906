import {Injectable} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap, finalize} from 'rxjs/operators';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  constructor(private spinner: NgxSpinnerService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this.spinner.show(); // Show spinner when request starts
    return next.handle(req).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            // Success response; you can handle specific tasks here if needed
          }
        },
        (error: HttpErrorResponse) => {
          // Handle errors here if needed
        },
      ),
      finalize(() => {
        this.spinner.hide(); // Hide spinner when request finishes or fails
      }),
    );
  }
}
