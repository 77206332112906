<div class="main-wrapper">
  <div>
    <div class="sign-in-title">
      <h2>StoryWeaver AI</h2>
      <p>Weave your business stories with intelligence and creativity</p>
    </div>

    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()"></form>

    <!-- Social login button -->
    <div class="google-button">
      <button nbButton shape="round" size="medium" (click)="loginViaGoogle()">
        <!-- <img
          src="../../../assets/images/auth/image.png"
          alt="googlelogo"
          margin-let="5px"
          draggable="false"
        /> -->
        Sign In with Google
      </button>
    </div>
  </div>
</div>
