export const environment = {
  baseApiUrl: '',
  clientId: 'sdlc',
  client_secret: 'saqw21!@',
  publicKey: 'saqw21!@',
  authServiceUrl: 'https://auth.sdlc-ai.sourcef.us',
  featureServiceUrl: 'https://feature.sdlc-ai.sourcef.us',
  userServiceUrl: '',
  homePath: '/main/dashboard',
  home: '',
  logLevel: '',
  tenantMgmtFacadeUrl: '',
  tenantmgmtServiceUrl: '',
  subscriptionServiceUrl: '',
  cognitoLogoutUrl: '',
};